import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { QueueService } from '../../../shared/component/grid-queues/services/queue.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
      <!-- <nb-layout-header fixed> -->
      <nb-layout-header fixed>
        <div class="d-flex w-100">
          <ngx-header></ngx-header>
          <!-- <div>test</div> -->
        </div>
      </nb-layout-header>

      <nb-sidebar #sb class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
        @if (sb?._state === 'expanded') {
          <nb-sidebar-footer tag="menu-sidebar" responsive>
            <div class="m-footer">
              <div>
                <stack-mono-dynamic-input
                  [height]="250"
                  viewType="select"
                  objectType="QueueViewGroup"
                  displayField="name"
                  placeholder="Change View Group"
                  (objectChange)="onViewGroupChange($event)"
                >
                </stack-mono-dynamic-input>
              </div>
              <div>{{ version }}</div>
            </div>
          </nb-sidebar-footer>
        }
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  @ViewChild('sb') sidebar: ElementRef;
  @Input() version: string;

  constructor(private readonly queueService: QueueService) {}

  onViewGroupChange(event) {
    this.queueService.changeViewGroup(event);
  }
}
