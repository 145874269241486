import { gql } from 'apollo-angular';

export const QUERY_GetEquipmentEMR = gql`
  query ($search: DtoPaginatedFilter!) {
    getEMRs(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          emrNum
          notes
          status
          createdAt
          updatedAt
          locationNum
          createdByRef {
            id
            firstname
            lastname
            fullName
            email
          }
          lines {
            id
            emrId
            description
            notes
            status
            startedAt
            unitLocatedAt
            completedAt
            issue
            resolution
          }
        }
      }
    }
  }
`;
