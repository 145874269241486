import { gql } from 'apollo-angular';

export const Query_GetLocationsPaginated = gql`
  query ($search: DtoPaginatedFilter!) {
    getLocationsPaginated(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          locationNum
          description
          externalId
          address
          address1
          address2
          city
          state
          zipcode
          country
          lat
          lng
          phoneNum
          fax
          locationType
        }
      }
    }
  }
`;
