import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
} from '@nebular/theme';
import { AgGridModule } from 'ag-grid-angular';
import { DynamicListViewModule } from '../dynamiclistview.module';
import { DynamicInputComponent } from './dynamic-input.component';

@NgModule({
  declarations: [DynamicInputComponent],
  exports: [DynamicInputComponent],
  imports: [
    CommonModule,
    NbCardModule,
    AgGridModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    DynamicListViewModule,
  ],
})
export class DynamicInputModule {}
