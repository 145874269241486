import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbOptionComponent,
  NbPopoverDirective,
} from '@nebular/theme';
import { WindowService } from 'packages/stack-web/src/app/@core/services/window.service';
import { EquipmentDetailComponent } from 'packages/stack-web/src/app/pages/equipment/detail/equipmentdetail.component';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'next-equipmentinput',
  templateUrl: './equipmentinput.component.html',
  styleUrls: ['./equipmentinput.component.scss'],
})
export class EquipmentInputComponent implements OnInit {
  _equipment: any;
  _customer: any = {};
  isLoading: boolean;
  equipmentNum = '';
  isTyping = false;
  searchSubject = new Subject<string>();
  orFilters: any;

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(NbPopoverDirective) popOverRef: NbPopoverDirective;
  @ViewChild('autooption') autooption: NbOptionComponent;
  @ViewChild(NbAutocompleteDirective)
  autocompleteDirective: NbAutocompleteDirective<string>;

  @Output() equipmentChange = new EventEmitter();
  @Input() hideEquipmentNum: boolean;
  @Input() locked = false;
  @Input() filters;
  @Input() fieldSize = 'medium';
  @Input() page: string;
  @Input() showPrefix = true;
  @Input() outline = false;

  @Input() set equipment(equipment: any) {
    this._equipment = equipment;
    this.updateLabel();
  }
  get equipment() {
    return this._equipment;
  }

  @Input() set customer(customer: any) {
    this._customer = customer;
  }
  get customer() {
    return this._customer;
  }

  constructor(
    @Inject(WindowService) private readonly windowService: WindowService,
  ) {}

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(700)).subscribe((text) => {
      this.isTyping = false;
      this.orFilters = [
        {
          column: 'equipmentNum',
          value: `*${text}*`,
        },
        {
          column: 'fleetCode',
          value: `*${text}*`,
        },
        {
          column: 'serial',
          value: `*${text}*`,
        },
      ];
    });
  }

  onPopOverStateChange(event) {
    if (
      !event.isShown &&
      !this.equipment?.id &&
      this.searchInput.nativeElement.value !== ''
    ) {
      this.equipmentNum = '';
      this.searchInput.nativeElement.value = '';
      this.searchSubject.next('');
    }
  }

  onFocus(event) {
    if (!this.popOverRef.isShown) {
      event.target.select();
    }
  }

  onKeyUp(event) {
    this.isTyping = true;
    if (this.equipment) {
      this.equipment = null;
    }
    if (!this.popOverRef.isShown) {
      this.popOverRef.show();
    }

    const text = event.target.value;
    this.searchSubject.next(text);
  }

  onBlur(event) {
    if (!this.equipment) {
      this.equipmentNum = '';
    } else {
      this.updateLabel();
    }
  }

  updateLabel() {
    if (this.isTyping) {
      return;
    }
    if (!this._equipment) {
      this.equipmentNum = '';
      return;
    }

    this.equipmentNum = this._equipment?.equipmentNum || '';
  }

  gotoEquipment() {
    this.windowService.openWindow(EquipmentDetailComponent, {
      context: {
        id: this.equipment?.id,
      },
      objectId: this.equipment?.id,
      options: {
        title: 'Equipment',
      },
    });
  }

  selected(equipment, template?) {
    this.equipment = equipment;
    this.updateLabel();
    this.equipmentChange.emit(equipment);
    this.popOverRef.hide();
    if (template) {
      template.hide();
    }
    if (!equipment) return;

    if (this.autooption) {
      this.autooption.onClick({
        preventDefault: () => {
          return;
        },
      });
    }
  }
}
