import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DtoAddQueue } from '../dto/dtoAddQueue.dto';
import { Mutation_AddQueue } from '../../../../@core/graphql/mutations/queues/addQueue.mutation';
import { Query_GetQueuesByObject } from '../../../../@core/graphql/queries/queues/getQueuesByObject.querie';
import { DtoGetQueuesByObject } from '../dto/DtoGetQueuesByObject';
import { Mutation_DeleteQueue } from '../../../../@core/graphql/mutations/queues/deleteQueue.mutation';
import { DtoUpdateQueue } from '../dto/dtoUpdateQueue';
import { Mutation } from '@nestjs/graphql';
import { Mutation_UpdateQueue } from 'packages/stack-web/src/app/@core/graphql/mutations/queues/updateQueue.mutation';
import { WatchQueryFetchPolicy } from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class QueueApiService {
  constructor(private readonly apollo: Apollo) {}

  async resetStore() {
    await this.apollo.client.reFetchObservableQueries();
  }
  addQueue(dto: DtoAddQueue) {
    return this.apollo.mutate({
      mutation: Mutation_AddQueue,
      variables: { dto },
    });
  }

  updateQueue(dto: DtoUpdateQueue) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateQueue,
      variables: { dto },
    });
  }

  getQueuesByObjectType(dto: DtoGetQueuesByObject) {
    return this.apollo.watchQuery({
      query: Query_GetQueuesByObject,
      variables: { dto },
    });
  }

  deleteQueue(id: string) {
    return this.apollo.mutate({
      mutation: Mutation_DeleteQueue,
      variables: { id },
      update: (cache) => {
        cache.evict({ id: `Queue:${id}` });
      },
    });
  }

  getQueryData(
    search,
    gqlQuery,
    pollInterval = 5 * 60 * 1000,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  ) {
    return this.apollo.watchQuery({
      query: gqlQuery,
      fetchPolicy,
      pollInterval,
      variables: { search: search },
    });
  }

  getQueryDataQuery(search, gqlQuery) {
    return this.apollo.query({
      query: gqlQuery,
      variables: { search: search },
    });
  }
}
