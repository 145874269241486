import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
@Component({
  selector: 'child-cell',
  //[innerHTML]="innerHtml
  template: `
    <div
      isDraggable="true"
      [attr.data-workOrderNum]="this.workOrderNum"
      attr.data-workOrder="{{ this.params?.data | json }}"
    >
      <nb-icon
        style="font-size:1.3em; pointer-events: none;"
        icon="calendar-outline"
        [attr.data-workOrderNum]="this.workOrderNum"
      ></nb-icon>
      {{ this.workOrderNum || '' }}
    </div>
  `,
  styles: [``],
})
export class DraggableCellRenderer implements ICellRendererAngularComp {
  public params: any;
  public workOrderNum: string;

  innerHtml: string;

  agInit(params: any): void {
    this.params = params;
    this.workOrderNum = params.value;
  }

  refresh(): boolean {
    return false;
  }
}
