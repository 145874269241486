import { gql } from 'apollo-angular';

export const QUERY_GetEquipmentEMRLines = gql`
  query ($search: DtoPaginatedFilter!) {
    getEMRLines(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          emrId
          description
          notes
          status
          startedAt
          unitLocatedAt
          completedAt
          toStation
          toArea
          issue
          issueNotes
          resolution
          equipmentId
          assignedTo
          locationNum
          priority
          createdAt
          emrRef {
            id
            emrNum
            notes
            status
            createdAt
            updatedAt
            locationNum
            createdByRef {
              id
              firstname
              lastname
              fullName
              email
            }
          }
          equipmentRef {
            id
            equipmentNum
            station
            make
            model
            serial
            fleetCode
            fleetType
            customerEquipmentNum
            status
          }
          createdByRef {
            id
            firstname
            lastname
            fullName
            email
          }
          assignedToRef {
            id
            firstname
            lastname
            fullName
            email
          }
        }
      }
    }
  }
`;
