import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbOptionComponent,
  NbPopoverDirective,
} from '@nebular/theme';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'stack-mono-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnInit {
  _selectedObject: any;
  isLoading: boolean;
  displayValue = '';
  isTyping = false;
  searchSubject = new Subject<string>();
  orFilters: any;

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(NbPopoverDirective) popOverRef: NbPopoverDirective;
  @ViewChild('autooption') autooption: NbOptionComponent;
  @ViewChild(NbAutocompleteDirective)
  autocompleteDirective: NbAutocompleteDirective<string>;

  @Output() objectChange = new EventEmitter();
  @Input() objectType: string; // e.g. 'Equipment', 'Customer', etc.
  @Input() searchFields: string[] = []; // Fields to search on
  @Input() displayField: string; // Field to display in input
  @Input() detailComponent: any; // Component to open when clicking the prefix button
  @Input() hidePrefix = false;
  @Input() locked = false;
  @Input() filters;
  @Input() fieldSize = 'medium';
  @Input() page: string;
  @Input() placeholder: string;
  @Input() showClearButton = false;
  @Input() viewType: 'input' | 'select' = 'input';
  @Input() height = 400;

  @Input() set selectedObject(obj: any) {
    this._selectedObject = obj;
    this.updateLabel();
  }
  get selectedObject() {
    return this._selectedObject;
  }

  constructor() {}

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(700)).subscribe((text) => {
      this.isTyping = false;

      this.orFilters = this.searchFields.map((field) => ({
        column: field,
        value: `*${text}*`,
      }));
    });
  }

  onPopOverStateChange(event) {
    if (
      !event.isShown &&
      !this.selectedObject?.id &&
      this.searchInput.nativeElement.value !== ''
    ) {
      this.displayValue = '';
      this.searchInput.nativeElement.value = '';
      this.searchSubject.next('');
    }
  }

  onFocus(event) {
    if (!this.popOverRef.isShown) {
      event.target.select();
    }
  }

  onKeyUp(event) {
    this.isTyping = true;
    if (this.selectedObject) {
      this.selectedObject = null;
    }
    if (!this.popOverRef.isShown) {
      this.popOverRef.show();
    }

    const text = event.target.value;
    this.searchSubject.next(text);
  }

  onBlur(event) {
    if (!this.selectedObject) {
      this.displayValue = '';
    } else {
      this.updateLabel();
    }
  }

  updateLabel() {
    if (this.isTyping) {
      return;
    }
    if (!this._selectedObject) {
      this.displayValue = '';
      return;
    }

    this.displayValue = this._selectedObject[this.displayField] || '';
  }

  gotoDetail() {
    if (!this.detailComponent) return;

    console.log('Details');

    // this.windowService.openWindow(this.detailComponent, {
    //   context: {
    //     id: this.selectedObject?.id,
    //   },
    //   objectId: this.selectedObject?.id,
    //   options: {
    //     title: this.objectType,
    //   },
    // });
  }

  clear() {
    this.selectedObject = null;
    this.displayValue = '';
    this.objectChange.emit(null);
  }

  selected(object, template?) {
    this.selectedObject = object;
    this.updateLabel();
    this.objectChange.emit(object);
    this.popOverRef.hide();
    if (template) {
      template.hide();
    }
    if (!object) return;

    if (this.autooption) {
      this.autooption.onClick({
        preventDefault: () => {
          return;
        },
      });
    }
  }
}
