import { gql } from 'apollo-angular';

export const Query_GetQueueViewGroups = gql`
  query GetQueueViewGroups($search: DtoPaginatedFilter!) {
    getQueueViewGroups(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;
