import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { BehaviorSubject, pipe } from 'rxjs';
import { Observable } from 'rxjs';
import { MUTATION_REFRESHTOKEN } from '../graphql/mutations/refreshtoken.mutation';
import jwt_decode from 'jwt-decode';
import { Apollo } from 'apollo-angular';
import { loginresponse } from '../graphql/types/loginresponse.type';
import { LOGIN_MUTATION } from '../graphql/mutations/login.mutation';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token: any;
  private _user$: any = new BehaviorSubject(false);
  public user: any;
  private _isAuthenticated$ = new BehaviorSubject(false);
  isAuthenticated: boolean = false;

  constructor(private router: Router) {
    this.getTokenFromStorage();
  }

  get() {
    return this._user$.asObservable();
  }

  get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }

  setToken(token: any) {
    localStorage.setItem('token', JSON.stringify(token || ''));
    this.token = token;
    const jwtuser: any = jwt_decode(token.accessToken);
    this.user = jwtuser;
    console.log(this.user);
    this._user$.next(jwtuser);
    this.isAuthenticated = true;
    this._isAuthenticated$.next(this.isAuthenticated);
  }

  getTokenFromStorage() {
    if (!this.token) {
      this.token = JSON.parse(localStorage.getItem('token'));
      if (!this.token) return null;
      try {
        const jwtuser: any = jwt_decode(this.token.accessToken);
        this.user = jwtuser;
        this._user$.next(jwtuser);
      } catch {
        console.log('Error getting token');
      }
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this._isAuthenticated$.next(this.isAuthenticated);
    this._user$.next();
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login'], {
      replaceUrl: true,
    });
  }
}
