import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { EquipmentService } from '../../../@core/services/equipment.service';
import { CustomerPopoverComponent } from '../../../shared/component/customers/popover/customer-popover.component';
import { MapService } from '../../../@core/services/map.service';

@Component({
  selector: 'next-equipment-detail',
  templateUrl: './equipmentdetail.component.html',
  styleUrls: ['./equipmentdetail.component.scss'],
})
export class EquipmentDetailComponent implements OnInit, OnDestroy {
  private _id: string;
  @Input() set id(id: string) {
    this._id = id;
    if (!id) return;
    this.getEquipment(id);
  }
  get id() {
    return this._id;
  }

  customerPopOver = CustomerPopoverComponent;
  equipment: any = {};
  customer: any;
  activeTab: string;
  equipmentIssues = '';
  equipmentIssueNotes = '';
  paramSub$;
  generalClosed = signal(false);
  currentLocationClosed = signal(false);
  purchaseClosed = signal(false);
  salesClosed = signal(false);
  serviceClosed = signal(false);
  generalShowMore = signal(false);
  currentLocationShowMore = signal(false);
  purchaseShowMore = signal(false);
  salesShowMore = signal(false);
  serviceShowMore = signal(false);
  windowRef;
  form = new FormGroup({
    id: new FormControl(''),
    serial: new FormControl(''),
    make: new FormControl(''),
    model: new FormControl(''),
    fleetType: new FormControl(''),
    notes: new FormControl(''),
    lastMeterReading: new FormControl(''),
    lasteMeterReadingDate: new FormControl(''),
    customerEquipmentNum: new FormControl(''),
    fleetCode: new FormControl(''),
    locationNum: new FormControl(''),
  });
  serviceVisit: any;

  constructor(
    private equipmentService: EquipmentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly toaster: NbToastrService,
    private mapService: MapService,
  ) {
    this.equipment.customer = {};
    if (this.id) {
      return;
    }
    this.paramSub$ = this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      this.id = id;
      this.getEquipment(id);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.paramSub$) this.paramSub$.unsubscribe();
  }

  customerChange(customer: any) {
    this.customer = customer;
  }

  onTabChange(event) {
    this.activeTab = event.tabTitle;
  }

  gotoWorkOrder(workOrder) {
    this.router.navigateByUrl(`/pages/workorders/${workOrder.id}`);
  }

  updateIssueReported(tag) {
    const issue = tag?.text;
    const issuesArray = this.equipmentIssues
      ? this.equipmentIssues?.split(',')
      : [];

    const index = issuesArray.indexOf(issue);
    if (index > -1) {
      issuesArray.splice(index, 1);
    } else {
      issuesArray.push(issue);
    }
    issuesArray.sort();
    this.equipmentIssues = issuesArray.join(',');
    this.updateEquipmentIssues();
  }

  updateIssueNotes(notes) {
    this.equipmentIssueNotes = notes;
    this.updateEquipmentIssues();
  }

  clearAllIssues() {
    this.equipmentIssues = '';
    this.equipmentIssueNotes = '';
    this.updateEquipmentIssues();
  }

  async updateEquipmentIssues() {
    const _ = await this.equipmentService
      .updateEquipment({
        id: this.id,
        issueReported: this.equipmentIssues,
        issueNotes: this.equipmentIssueNotes,
      })
      .toPromise();
  }

  getEquipment(id) {
    if (!id) return;
    this.equipmentService.getEquipmentById(id).subscribe(({ data }) => {
      const equipment: any = data;
      this.equipment = equipment.getEquipmentById;
      this.form.reset();
      this.form.patchValue(this.equipment);
      this.equipmentIssueNotes = this.equipment.issueNotes;
      this.equipmentIssues = this.equipment.issueReported;
      // ? this.equipment.issueReported.indexOf(',') === -1
      //   ? [this.equipment.issueReported]
      //   : this.equipment.issueReported.split(',')
      // : [];

      console.log('equipmentIssues', this.equipmentIssues);

      this.customer = this.equipment.customer;
      if (this.windowRef) {
        this.windowRef.config.title = `${this.equipment?.equipmentNum} - ${this.equipment?.serial}`;
      }
      this.getMap();
    });
  }

  async getMap() {
    let cust = this.equipment?.customer
      ? this.equipment?.customer
      : {
          address1: '15217 GRAND RIVER RD',
          address2: '',
          city: 'FORT WORTH',
          state: 'TX',
          zipcode: '76155',
          country: '',
        };
    let travelFrom = this.equipment?.customer
      ? { lat: '32.8157499', lng: '-97.0374748' }
      : { lat: '39.1411816', lng: '-85.9507502' };

    let country = cust?.country ? cust?.country : 'US';
    let address =
      cust?.address1 +
      ' ' +
      cust?.city +
      ', ' +
      cust?.state +
      ' ' +
      cust?.zipcode +
      ' ' +
      country;

    const latlng: any = await this.mapService.goecode(address);
    let lat = latlng.lng;
    let lng = latlng.lat;

    const directions: any = await this.mapService.getMapBoxDirections(
      [lng, lat],
      [travelFrom.lng, travelFrom.lat],
    );

    const url = this.mapService.getStaticMapMarkerImage(
      { lng, lat },
      { x: 300, y: 300 },
      12,
    );

    this.serviceVisit = {
      lat: lat,
      lng: lng,
      mapURL: url.replace('pin-s+', 'pin-l+'),
      address: address,
      address1: cust?.address1,
      address2: cust?.address2,
      city: cust?.city,
      state: cust?.state,
      zipcode: cust?.zipcode,
      country: country,
      category: 'Field',
      status: 'Planned',
      locationId: 'cl0o7zm6b0061pbxuyc8zfia6',
      estTravelDistance: ((directions?.distance / 1000) * 0.621371).toFixed(2),
      estTravelTime: (directions?.duration / 60).toFixed(0),
      estTravelFromLatLng: travelFrom.lat + ',' + travelFrom.lng,
      estTravelPolyline: directions.geometry,
      statusRef: {
        color: 'green',
      },
      isActive: false,
      timeSheets: [],
      customer: {
        id: cust?.id,
        name: cust?.name,
        customerNum: cust?.customerNum,
      },
      serviceVisitWorkers: [],
      serviceVisitWorkOrders: [],
    };
  }

  updateEquipment() {
    let update = this.form.value;
    update.lasteMeterReadingDate = undefined;
    //update.customerName = undefined;
    //update.customerId = this.customer?.id;
    /*this.equipmentService.updateEquipment(update).subscribe(({ data }) => {
      this.toaster.primary('Save Successfull!', 'Equipment Updated');
      //this.getCustomer(this.id);
    });*/
  }

  onGeneralClosed(event: any) {
    this.generalClosed.set(event);
  }

  onCurrentLocationClosed(event: any) {
    this.currentLocationClosed.set(event);
  }

  onPurchaseClosed(event: any) {
    this.purchaseClosed.set(event);
  }

  onSalesClosed(event: any) {
    this.salesClosed.set(event);
  }

  onServiceClosed(event: any) {
    this.serviceClosed.set(event);
  }

  toggleGeneralShowMore(event: any) {
    this.generalShowMore.set(!this.generalShowMore());
    event.stopPropagation();
  }

  toggleCurrentLocationShowMore(event: any) {
    this.currentLocationShowMore.set(!this.currentLocationShowMore());
    event.stopPropagation();
  }

  togglePurchaseShowMore(event: any) {
    this.purchaseShowMore.set(!this.purchaseShowMore());
    event.stopPropagation();
  }

  toggleSalesShowMore(event: any) {
    this.salesShowMore.set(!this.salesShowMore());
    event.stopPropagation();
  }

  toggleServiceShowMore(event: any) {
    this.serviceShowMore.set(!this.serviceShowMore());
    event.stopPropagation();
  }
}

//TODO ADD EQUIPMENT COMPONENT AND TO CUSTOMERS
