import { gql } from 'apollo-angular';

export const QUERY_GetEquipment = gql`
  query ($search: DtoPaginatedFilter!) {
    getEquipment(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          equipmentNum
          serial
          make
          model
          group
          category
          modelRef {
            group
            category
          }
          locationNum
          branchNum
          customerEquipmentNum
          status
          orderStatus
          station
          barcode
          postingStatus
          orderDate
          issueReported
          issueNotes
          estimatedReceiptDate
          factoryShipDate
          actualReceiptDate
          fleetType
          fleetCode
          rentalContractNum
          rentalContractStatus
          rentalCustomerNum
          rentalCustomerName
          description
          actualReceiptDate
          deliveryDate
          status
          customer {
            id
            name
            customerNum
            address1
            address2
            city
            state
            zipcode
            country
            lat
            lng
            billTo {
              id
              name
              customerNum
            }
          }
        }
      }
    }
  }
`;
