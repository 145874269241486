import { Inject, Injectable, isDevMode, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  localApiBaseURI = 'http://localhost:3000/api';
  localGraphQL = 'http://localhost:3000/graphql';
  localGraphQLWS = 'ws://localhost:3000/graphq';
  devApiBaseURI = 'https://dev.api.nextfieldservice.com/api';
  devGraphQL = 'https://dev.api.nextfieldservice.com/graphql';
  devGraphQLWS = 'wss://dev.api.nextfieldservice.com/graphql';
  prodApiBaseURI = 'https://prod.api.stacksoftware.com/api';
  prodGraphQL = 'https://prod.api.stacksoftware.com/graphql';
  prodGraphQLWS = 'wss://prod.api.stacksoftware.com/graphql';
  isProd = signal(true);
  experimentalFeatures = signal(true);
  svdOptions = signal(false);

  activeApiBaseURI = this.prodApiBaseURI;
  activeGraphQL = this.prodGraphQL;
  activeGraphQLWS = this.prodGraphQLWS;
  activeEnv = signal('production');

  constructor() {
    const env = localStorage.getItem('env' as any) as any;
    this.setEnvURI(env || 'production');
  }

  setEnvURI(env: 'local' | 'development' | 'production') {
    localStorage.setItem('env', env);

    if (isDevMode()) {
      this.activeApiBaseURI = this.localApiBaseURI;
      this.activeGraphQL = this.localGraphQL;
      this.activeGraphQLWS = this.localGraphQLWS;
      this.experimentalFeatures.set(true);
      localStorage.setItem('env', 'development');
      this.activeEnv.set('development');
      return;
    }

    this.activeEnv.set(env);
    if (env === 'local') {
      this.activeApiBaseURI = this.localApiBaseURI;
      this.activeGraphQL = this.localGraphQL;
      this.activeGraphQLWS = this.localGraphQLWS;
      this.experimentalFeatures.set(true);
    } else if (env === 'development') {
      this.activeApiBaseURI = this.devApiBaseURI;
      this.activeGraphQL = this.devGraphQL;
      this.activeGraphQLWS = this.devGraphQLWS;
      this.experimentalFeatures.set(true);
    } else if (env === 'production') {
      this.activeApiBaseURI = this.prodApiBaseURI;
      this.activeGraphQL = this.prodGraphQL;
      this.activeGraphQLWS = this.prodGraphQLWS;
      this.experimentalFeatures.set(false);
    }
  }

  getExperimentalFeatures(): boolean {
    return this.experimentalFeatures();
  }

  get activeURI(): string {
    return this.activeGraphQL;
  }

  getCurrentEnv(): string {
    return this.activeEnv();
  }
}
