import { gql } from 'apollo-angular';

export const Query_GetBranchesPaginated = gql`
  query ($search: DtoPaginatedFilter!) {
    getBranchesPaginated(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          branchNum
          description
          externalId
          address
          address1
          address2
          city
          state
          zipcode
          country
          lat
          lng
          phone
          fax
          location {
            id
            locationNum
            description
          }
        }
      }
    }
  }
`;
