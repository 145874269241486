import { gql } from 'apollo-angular';

export const Mutation_UpdateQueue = gql`
  mutation updateQueue($dto: DtoUpdateQueue!) {
    updateQueue(dto: $dto) {
      id
      object
      name
      page
      sort
      type
      filter
      orFilter
      badgeLogic
      userId
      showOnDashboard
      user {
        id
        email
        avatarURL
        fullName
      }
      userRoleId
      userRoleRef {
        id
        name
      }
    }
  }
`;
