import { gql } from 'apollo-angular';

export const Query_GetStations = gql`
  query ($search: DtoPaginatedFilter!) {
    getStations(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          station
          description
          area
          locationRef {
            id
            locationNum
            address
          }
        }
      }
    }
  }
`;
